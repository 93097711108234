/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('babel-polyfill')

// eslint-disable-next-line import/prefer-default-export
export const onClientEntry = () => {
    // Without this function body the import will not be picked up.
}
