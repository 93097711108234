module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-0TVY8HWCV9","head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src308074764/src/planly.nl/src/intl","languages":["nl","en"],"defaultLanguage":"nl","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Planly","short_name":"Planly","start_url":"/","background_color":"#e1717f","theme_color":"#e1717f","display":"minimal-ui","lang":"nl","icon":"/codebuild/output/src308074764/src/planly.nl/static/images/planly-favicon.png","localize":[{"start_url":"/nl/","lang":"nl","name":"Planly – Altijd op de hoogte van uw afspraken","short_name":"Planly","description":"Planning software, afspraken inplannen, datumprikker"},{"start_url":"/en/","lang":"en","name":"Planly – always aware of your appointments","short_name":"Planly","description":"Planning software, manage appointments, date picker"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dc2504f0dd667fdebbb2390ca156412b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto","Montserrat:600,700,800"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
